import React from 'react';
import { useUserData } from './useUserData';
import RegisterForm from "./RegisterForm";
import Top from "./Top";

const Register = () => {
    const {user} = useUserData();

    if (user) {
        return (
            <Top/>
        )

    } else {
        return (
            <RegisterForm/>
        )
    }
};

export default Register;
