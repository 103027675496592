import Footer from "./Footer";
import Header from "./Header";
import React, {useState} from "react";
import {auth} from "../firebaseConfig";
import {sendEmailVerification} from "firebase/auth";

const CorpRegisterComplete = ({user, userData}) => {
    const [emailSent, setEmailSent] = useState(false);

    // 確認メールを再送信
    const resendVerificationEmail = async (e) => {
        if (user && !user.emailVerified) {
            try {
                e.target.disabled = true;
                await sendEmailVerification(user); // メール確認メールを送信
                setEmailSent(true);
            } catch (error) {
                if (error.code === 'auth/too-many-requests') {
                    alert('連続で送信できません。しばらくお待ちください。');
                } else {
                    alert('エラーが発生しました。');
                }
                e.target.disabled = false;
            }
        }
    };

    // ログアウト
    const handleLogout = async () => {
        await auth.signOut();
    };

    if (!user.emailVerified) { // メールアドレスが未認証の場合
        return (
            <div>
                <Header/>
                <div className="container mt-5">
                    <h5>シンソクトレカ卸グループ申し込み</h5>
                    <p>お客様のメールアドレス宛に確認メールを送信しました。メール内のリンクを押していただくと申込完了となります。</p>
                    <p>もしメールが届かない場合は、迷惑メールフォルダをご確認いただくようお願いいたします。</p>
                    <p>迷惑メールの対策などでドメイン指定を行っている場合は「@shinsoku-tcg.com」を<a href="/maildomain"
                                                                                                   target="_blank">受信設定してください。</a></p>
                    <p>再度確認メールを送信する場合、下記ボタンを押してください。</p>
                    <div style={{textAlign: 'center', marginBottom: '50px'}}>
                        <button onClick={resendVerificationEmail}>確認メールを再送信</button>
                        {emailSent && <p>認証メールが送信されました！</p>}
                    </div>
                    <p>登録手続きが正常に完了しない場合は、誠にお手数ではございますが、以下のいずれかの方法にてお問い合わせくださいますようお願い申し上げます。</p>
                    <ul>
                        <li>電話番号: 03-6826-0999（12:30～21:00／年中無休）</li>
                        <li>E-mail: <a href="mailto:info@cardshop-shinsoku.jp">info@cardshop-shinsoku.jp</a></li>
                        <li>公式LINE: <a href="https://lin.ee/H01De2E">https://lin.ee/H01De2E</a></li>
                    </ul>
                    <p>別のメールアドレスで登録する場合は下記を押してください。</p>
                    <div style={{textAlign: 'center', marginBottom: '50px'}}>
                        <button onClick={handleLogout}>ログアウト</button>
                    </div>
                    <p>上記完了後、弊社所定の審査がございます。（審査期間の目安：即日から3営業日以内）</p>
                    <p>審査を通過された場合にのみ、ご登録のメールアドレスにグループの招待リンクをお送りいたします。</p>
                </div>
                <Footer/>
            </div>
        );
    } else {
        return (
            <div>
                <Header/>
                <div className="container mt-5">
                    <h5>シンソクトレカ卸グループ申し込み完了</h5>
                    <p>弊社所定の審査中でございます。（審査期間の目安：即日から3営業日以内）</p>
                    <p>審査を通過された場合にのみ、ご登録のメールアドレスにグループの招待リンクをお送りいたします。</p>
                </div>
                <Footer/>
            </div>
        );
    }
};

export default CorpRegisterComplete;
