import React from 'react';
import { useUserData } from './useUserData';
import CorpRegisterForm from "./CorpRegisterForm";
import Top from "./Top";

const CorpRegister = () => {
    const {user} = useUserData();

    if (user) {
        return (
            <Top/>
        )

    } else {
        return (
            <CorpRegisterForm/>
        )
    }
};

export default CorpRegister;
