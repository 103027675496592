import React from "react";
import Header from "./Header";
import Footer from "./Footer";

function MailDomain() {
    return (
        <div>
            <Header />
            <div className="container mt-5">
                <h5 style={{textAlign: 'center'}}>メール受信許可設定のお願い</h5>
                <p>セキュリティ設定や迷惑メール対策等で、当社からのメールが正しく届かないことがございます。お手数をおかけしますが、下記ドメインのメールを受信できるように、
                    迷惑メール設定から解除、もしくは受信許可設定をしていただくようお願いいたします。</p>
                <p style={{fontSize: "1.5em"}}>@shinsoku-tcg.com</p>

                <h5 style={{textAlign: 'center'}}>設定方法</h5>
                <p>受信設定の方法につきましては、各メールソフトや端末、キャリアによって設定方法が異なります。各種、お使いのメールソフト及び携帯会社へお問い合わせください。</p>
                <ol>
                    <li style={{fontSize: "1.5em"}}><a href="https://www.softbank.jp/mobile/support/mail/antispam/mms/whiteblack/" target="_blank" rel="noreferrer">SoftBank</a></li>
                    <li style={{fontSize: "1.5em"}}><a href="https://www.docomo.ne.jp/info/spam_mail/spmode/domain/docomo_mail_setting/" target="_blank" rel="noreferrer">docomo</a></li>
                    <li style={{fontSize: "1.5em"}}><a href="https://www.au.com/support/service/mobile/trouble/mail/email/filter/detail/domain/" target="_blank" rel="noreferrer">au</a></li>
                </ol>
            </div>
            <Footer/>
        </div>
    );
}

export default MailDomain;