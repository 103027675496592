import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {auth} from "../firebaseConfig";
import Footer from "./Footer";
import ItakuHanbaiKiyakuNoHeader from "./ItakuHanbaiKiyakuNoHeader";

const ConsignmentSales = () => {
    const [isAgreed, setIsAgreed] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserData = async () => {
            const currentUser = auth.currentUser;
            if (!currentUser) {
                // 未ログインの場合、トップページへリダイレクト
                navigate('/');
            }
        };

        fetchUserData().then();
    }, [navigate]);

    const handleAgreementChange = (e) => {
        setIsAgreed(e.target.checked);
    };

    const handleSubmit = () => {
        navigate("/profile");
    };

    return (
        <div>
            <div className="container mt-5">
                <h5 style={{textAlign: 'center'}}>委託販売依頼</h5>
                <br/>
                <a href="https://www.cardshop-shinsoku.jp/page/itakuhanbai" style={{fontSize:"1em"}} target="_blank" rel="noopener noreferrer">委託販売の流れ</a>
                <br/>
                <div style={{overflowY: 'scroll', height: '300px', border: '1px solid #ccc', marginTop:"10px"}}>
                    <ItakuHanbaiKiyakuNoHeader/>
                </div>
                <br/>
                <div>
                    <label>
                        <input
                            type="checkbox"
                            checked={isAgreed}
                            onChange={handleAgreementChange}
                        />
                        上記委託規約に同意して委託販売を依頼します。
                    </label>
                </div>
                <div style={{textAlign: 'center'}}>
                    <button
                        className="btn btn-primary mt-3"
                        onClick={handleSubmit}
                        disabled={!isAgreed}
                    >
                        委託販売依頼
                    </button>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default ConsignmentSales;
