import React from "react";

function ItakuHanbaiKiyakuNoHeader() {
    return (
        <div>
            <h5 style={{textAlign: 'center'}}>委託規約</h5>
            <div className="container" style={{fontSize: "1em"}}>
                <p>株式会社ＫＸオンライン（以下「弊社」といいます。）は、商品の委託販売サービス（以下「本サービス」といいます。）の提供に際し、委託者から商品の販売の委託を受けること（以下「本委託販売」といいます。）について、以下のとおり委託規約（以下「本規約」といいます。）を定めます。本サービスを利用して弊社に商品の販売を委託する者（以下「委託者」といいます。）は、弊社に対し本委託販売を委託する前に、必ず利用規約及び本規約並びに弊社が別途定めるプライバシーポリシー（以下「プライバシーポリシー」といいます。）を確認しなければなりません。委託者が弊社に本委託販売を委託した時点で、利用規約、本規約並びにプライバシーポリシーの全ての内容を承諾したものとみなします。</p>

                <h5>第1条（適用）</h5>
                <ol>
                    <li>本規約は、本委託販売に関する弊社と委託者との間の権利義務関係を定めることを目的とし、別途書面による合意がなされた場合を除き、委託者が弊社に委託する本委託販売及び本サービスに関わる全ての関係に適用されます。</li>
                    <li>弊社が運営するウェブサイト（以下「本サイト」といいます。）及び提携サイトに掲載する本委託販売に関するルールや諸規定等は本規約の一部を構成します。</li>
                    <li>弊社と委託者との間に個別の書面による合意がある場合、その合意が本規約に優先します。</li>
                </ol>

                <h5>第2条（本人確認）</h5>
                <ol>
                    <li>本委託販売を委託する際、古物営業法及び犯罪による収益の移転防止に関する法律に基づき、弊社指定の方法で本人確認を行います。本人確認が完了しない場合、本委託販売は受け付けられません。</li>
                    <li>本サービスの利用申込み後に虚偽の内容を申請したことが判明するなど、弊社が本委託販売を受け付けないと判断した場合、その旨をご連絡し、さらに委託者の利用登録を抹消することがあります。委託者はこれに異議を唱えないものとします。ただし、利用登録の抹消時点で第8条の売買が成立した商品がある場合、第9条に基づき商品代金の精算を行います。</li>
                </ol>

                <h5>第3条（本委託販売）</h5>
                <ol>
                    <li>委託者は、弊社所定の方法で商品の販売を弊社に委託します。弊社が本委託販売を受け付ける場合でも、当該商品の売却や買取を保証するものではありません。</li>
                    <li>委託者は、本委託販売を委託する際、以下の方法で利用登録（以下「本申込」といいます。）を行い、弊社が本委託販売を受け付ける旨を通知した時点で、当該商品の販売に関する委託契約が成立します。弊社は委託者から申込みに係る商品を受領後、状態確認等を行い、本委託販売を受け付けるか否かを通知します。これ以前に委託契約は成立しません。
                        <ul>
                            <li>本サイトに必要事項を記載したのちに会員登録をすること</li>
                        </ul>
                    </li>
                    <li>商品が以下のいずれかに該当する場合、本委託販売は受け付けられません。委託者は、弊社が前項に規定する通知を行った後でも、商品が以下の各号のいずれかに該当すると弊社が判断した場合、委託契約が取り消されることを異議なく承諾します。なお、弊社から委託者への商品の返却に要する費用は委託者が負担し、弊社から費用の請求があった場合、直ちに支払うものとします。
                        <ol>
                            <li>委託者本人の所有ではない商品</li>
                            <li>クレジットカードやショッピングクレジットの支払いが終了していない商品</li>
                            <li>弊社が別途定める受け入れ商品ルールに記載されていない商品</li>
                            <li>全体若しくは一部が破損している商品、機能が利用できない商品、通常の用法に従った使用ができない商品</li>
                            <li>偽造品、盗難品その他第三者の権利（知的財産権を含む）を侵害している又は疑われる商品</li>
                            <li>法令等により売買が禁止されている商品</li>
                            <li>弊社の指定する方法以外で弊社へ配送された商品</li>
                            <li>弊社による保管に相応の費用が発生する商品</li>
                            <li>その他、弊社が販売が困難であると判断した商品</li>
                        </ol>
                    </li>
                </ol>

                <h5>第4条（商品のお引き渡し）</h5>
                <ol>
                    <li>委託者は、本申込後、店舗に持ち込みを行うか、配送手配を行い、弊社に当該商品を引き渡します。ただし、商品の引渡しにより委託契約が成立するものではなく、委託契約の成立は第3条第2項の定めによります。</li>
                    <li>商品と一緒に引き渡された各種付属品については、弊社の判断で破棄することができ、委託者はこれに異議を唱えません。委託者は商品を送付する際、これらの付属品を取り除いて引き渡します。</li>
                    <li>弊社が商品を受領した時点で商品が破損している、販売困難な状態である、その他第3条第3項各号に該当し販売できないと判断した場合、その旨を通知し、委託者は商品の所有権を放棄し引き取りを希望するか、返却を希望するかを通知します。</li>
                    <li>商品の所有権を放棄し引き取りを希望する場合、弊社は販売又は廃棄を行うことができ、委託者は販売利益を請求しません。商品の返却を希望する場合、返却費用は委託者が負担し、請求があった場合、直ちに支払います。</li>
                    <li>通知ができない場合や通知後30日以内に指示がない場合、所有権を放棄し引き取りを希望したものとみなします。メール設定により通知が届かない場合も同様の処理を行います。</li>
                    <li>第3条第2項に基づき委託契約が成立した場合、第7条の委託販売期間中、弊社は商品を返却しません。本申込内容と異なる商品や商品の入っていない梱包物を送付した場合、弊社は損害賠償を請求できるものとします。</li>
                </ol>

                <h5>第5条（販売価格の決定）</h5>
                <ol>
                    <li>商品の販売価格は、弊社の査定評価額に基づき決定し、通知されます。委託者は評価額に同意するか、任意の数値を入力することで販売価格及び値下げ限度額を決定いたします。</li>
                    <li>販売価格に同意しない場合、所有権を放棄し引き取りを希望するか、返却を希望するかを通知します。所有権放棄の場合、第4条第4項が準用されます。返却の場合、第4条第5項が準用されます。</li>
                    <li>利用期間中、弊社より買取価格を提示し、買取処理を提案することがあります。住所やアドレス変更等で通知できない場合、30日以内に評価額の同意や通知がない場合、返却希望の場合で受取が困難な場合、第4条第5項が準用されます。</li>
                    <li>買取処理希望の場合、買取提案金額に基づき売買契約が成立し、所有権が移転します。振込手数料は弊社負担で代金が支払われます。</li>
                </ol>

                <h5>第6条（販売方法）</h5>
                <ol>
                    <li>本委託販売は、弊社が運営する通販サイトや店舗で設定した商品タイトルで公開します。弊社は任意の方法で第三者に本委託販売を行うことができます。</li>
                    <li>販売価格に基づき販売を開始し、値下げ限度額を下回らない範囲で自由に掲載販売します。宣伝活動に活用する場合があります。</li>
                    <li>7日以内に売れない場合、値下げを申し入れ、同意の上、新たな販売価格を決定します。同意しない場合、所有権を放棄し引き取り希望、買取処理希望、返却希望のいずれかを通知します。30日以内に返答がない場合、値下げに同意したものとみなします。</li>
                    <li>所有権放棄の場合、第4条第4項が準用されます。返却希望の場合、第4条第5項が準用されます。通知できない場合、60日以内に受取がない場合等、第4条第5項が準用されます。</li>
                </ol>

                <h5>第7条（委託販売期間）</h5>
                <p>委託販売期間は、販売価格決定日から30日間とし、別途認める場合に変更できます。期間内は、特別な承諾がない限り、解約、商品の返却、交換はできません。</p>

                <h5>第8条（売買契約の成立）</h5>
                <ol>
                    <li>会員が商品を選択し、注文内容を確定し、弊社が承諾した時点で売買契約が成立します。商品の所有権及び危険負担は、弊社から会員又は第三者に引き渡した時点で移転します。</li>
                    <li>商品の瑕疵については、故意・過失問わず、委託者は弊社の損害を賠償します。第三者からの損害賠償請求等があった場合、委託者は直ちに賠償します。</li>
                </ol>

                <h5>第9条（商品代金の支払い）</h5>
                <ol>
                    <li>商品代金は、弊社が委託者に通知する計算式に従います。販売期間中、販売価格以下で商品を販売することができ、商品代金を支払います。売却確定から7日以内に、指定の銀行口座に振り込みます（振込手数料は弊社負担）。</li>
                    <li>利用登録事項に変更がある場合、遅滞なく通知し変更手続きを行います。変更手続きが行われない場合、支払いができないことがあり、遅延利息等の責任は負いません。</li>
                </ol>

                <h5>第10条（売買契約が成立しなかった商品の処理）</h5>
                <ol>
                    <li>委託販売期間中に売買が成立しなかった場合、弊社は遅滞なく通知し、委託者は14日以内に再度委託、所有権放棄、買取処理、返却のいずれかを通知します。再度委託の場合、本規約の条件が適用されます。</li>
                    <li>所有権放棄の場合、第4条第4項が準用されます。返却希望の場合、第4条第5項が準用されます。通知ができない場合、30日以内に指示がない場合、返却希望で受取が困難な場合、第4条第5項が準用されます。</li>
                    <li>買取処理希望の場合、第5条第4項が準用されます。</li>
                </ol>

                <h5>第11条（商品管理）</h5>
                <p>弊社の故意又は重過失により、本委託販売に係る商品が破損・滅失しせたことにより委託販売期間において当該商品の販売ができなくなった場合には、弊社と委託者との協議に基づき決定する金額にて、賠償するものとします。但し、いかなる場合においても弊社による賠償金額は、第5条に基づき販売価格が決定している場合には当該販売価格、販売価格が決定していない場合には弊社の評価額を超える場合はありません。</p>

                <h5>第12条（禁止行為）</h5>
                <ol>
                    <li>委託者は、以下の行為を行ってはなりません。
                        <ol>
                            <li>弊社、会員その他の第三者の権利を侵害する行為</li>
                            <li>損害を生じさせるおそれのある目的又は方法で委託すること</li>
                            <li>パスワード及びIDの第三者への譲渡、貸与等</li>
                            <li>偽造品、盗難品等の権利侵害を告知しないこと</li>
                            <li>犯罪行為、公序良俗に反する行為</li>
                            <li>法令、内部規則違反</li>
                            <li>本サービスに関して利用しうる情報を改ざんする行為</li>
                            <li>本サイト、本委託販売、本サービスの運営妨害</li>
                            <li>その他、弊社が不適切と判断する行為</li>
                        </ol>
                    </li>
                </ol>

                <h5>第13条（本サイトの停止等）</h5>
                <ol>
                    <li>以下の場合、委託者に通知なく本サイトの全部又は一部を停止・中断できます。委託販売期間は延長されません。
                        <ol>
                            <li>コンピューター、通信回線等の事故</li>
                            <li>天災地変等の不可抗力</li>
                            <li>その他、弊社が必要と判断した場合</li>
                        </ol>
                    </li>
                    <li>この措置により生じた損害について、弊社は責任を負いません。</li>
                </ol>

                <h5>第14条（利用登録取消及び解除）</h5>
                <ol>
                    <li>以下の場合、通知なく本委託販売を停止、利用登録を取消、委託販売契約を解除できます。解除された場合、第10条に定める措置をとり、委託販売期間は延長されません。
                        <ol>
                            <li>本規約、利用規約違反があった場合</li>
                            <li>会員資格がないことが判明した場合</li>
                            <li>第13条の事由に該当した場合</li>
                            <li>損害を生じさせる目的・方法で利用した場合</li>
                            <li>本サイト・本委託販売・本サービスの運営妨害した場合</li>
                            <li>支払停止若しくは支払不能となり、又は破産手続、民事再生手続、会社更生手続、特別清算若しくはこれらに類する手続の開始の申立てがあった場合</li>
                            <li>自ら振出し、若しくは引受けた手形若しくは小切手につき、不渡りの処分を受けた場合、又は手形交換所の取引停止処分その他これに類する措置を受けた場合</li>
                            <li>差押、仮差押、仮保全、仮処分、強制執行又は競売の申立てがあった場合</li>
                            <li>租税公課の滞納処分を受けた場合</li>
                            <li>死亡した場合又は後見開始、保佐開始若しくは補助開始の審判を受けた場合</li>
                            <li>その他、弊社が継続困難と判断した場合</li>
                        </ol>
                    </li>
                    <li>この行為により生じた損害について、弊社は責任を負いません。</li>
                </ol>

                <h5>第15条（紛争処理及び損害賠償）</h5>
                <p>商品に偽造品、盗難品、権利侵害商品が含まれ第三者からの請求があった場合、委託者の責任と費用負担で対応します。弊社が費用を支出した場合、委託者は直ちに支払います。</p>

                <h5>第16条（秘密保持）</h5>
                <ol>
                    <li>本規約において「秘密情報」とは、委託販売契約、本サービスに関連して委託者が弊社より書面、口頭若しくは記録媒体等により提供若しくは開示されたか、又は知り得た、弊社の技術、営業、業務、財務、組織、その他の事項に関する全ての情報を意味します。但し、（1）弊社から提供若しくは開示がなされたとき又は知得したときに、既に一般に公知となっていた、又は委託者が既に知得していたもの、（2）弊社から提供若しくは開示又は知得した後、委託者の責めに帰せざる事由により刊行物その他により公知となったもの、（3）委託者が提供又は開示の権限のある第三者から秘密保持義務を負わされることなく適法に取得したもの、（4）秘密情報によることなく単独で開発したものであることを明確に示すことができる情報については秘密情報から除外します。</li>
                    <li>委託者は、秘密情報を本委託販売の目的のみに利用するとともに弊社の事前の書面による承諾なしに第三者に弊社の秘密情報を提供、開示又は漏洩しないものとします。</li>
                    <li>第2項の定めにかかわらず、委託者は、法律、裁判所又は政府機関の命令、要求又は要請に基づき、秘密情報を開示することができます。但し、当該命令、要求又は要請があった場合、開示の前にその旨を弊社に通知しなければならないものとし、必要な範囲のみ開示するものとします。なお、かかる場合においても、当該命令、要求又は要請をなした裁判所又は政府機関以外の第三者に対しては、弊社の書面による承諾なしに弊社の秘密情報を提供、開示又は漏洩しないものとします。</li>
                    <li>委託者は、秘密情報を記載した文書又は磁気記録媒体等を複製する場合には事前に弊社の書面による承諾を得ることとし、複製物の管理については第2項に準じて厳重に行うものとします。</li>
                    <li>委託者は、弊社から求められた場合にはいつでも、遅滞なく弊社の指示に従い、秘密情報並びに秘密情報を記載又は包含した書面その他の記録媒体物及びその全ての複製物を返却又は廃棄し、弊社の求めに応じて返却又は破棄した旨の証明書を交付しなければなりません。</li>
                </ol>

                <h5>第17条（個人情報の取扱い）</h5>
                <p>個人情報の取扱いはプライバシーポリシーに従い、同意します。個人を特定できない形で統計情報として利用及び公開します。</p>

                <h5>第18条（不可抗力）</h5>
                <p>天災地変、法令の制定改廃、その他の不可抗力又はこれに類する弊社の責に帰すことのできない事由によって本委託販売の履行が不可能となった場合、これにより委託者に生じた損害については弊社は一切責任を負わないものとします。</p>

                <h5>第19条（規約の変更）</h5>
                <p>一定期間前に公表し規約を変更できます。変更後の規約は、本サイトに掲載し告知します。変更の効力は翌日午前零時から発生し、以降の申込みに適用されます。</p>

                <h5>第20条（裁判管轄）</h5>
                <p>委託者と弊社との協議にもかかわらず、本サービス又は本委託販売に関する紛争について、裁判により解決を図ることになった場合はその訴額に応じて東京簡易裁判所又は東京地方裁判所を第一審の専属的合意管轄裁判所とします。</p>
            </div>
        </div>
    );
}

export default ItakuHanbaiKiyakuNoHeader;