import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Top from './pages/Top';
import Register from './pages/Register';
import Login from './pages/LoginPassword';
import Logout from './pages/Logout';
import Profile from './pages/Profile';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Kiyaku from './pages/Kiyaku';
import Access from './pages/Access';
import RequestValuation from "./pages/RequestValuation";
import PasswordChange from "./pages/PasswordChange";
import PasswordReset from "./pages/PasswordReset";
import ProfileEdit from "./pages/ProfileEdit";
import ConsignmentSales from "./pages/ConsignmentSales";
import CorpRegister from "./pages/CorpRegister";
import MailDomain from "./pages/MailDomain";

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Top/>}/>
                <Route path="/register" element={<Register/>}/>
                <Route path="/login" element={<Login/>}/>
                <Route path="/logout" element={<Logout/>}/>
                <Route path="/profile" element={<Profile/>}/>
                <Route path="/profileedit" element={<ProfileEdit/>}/>
                <Route path="/passwordchange" element={<PasswordChange/>}/>
                <Route path="/passwordreset" element={<PasswordReset/>}/>
                <Route path="/privacypolicy" element={<PrivacyPolicy/>}/>
                <Route path="/kiyaku" element={<Kiyaku/>}/>
                <Route path="/access" element={<Access/>}/>
                <Route path="/requestvaluation" element={<RequestValuation/>}/>
                <Route path="/consignmentsales" element={<ConsignmentSales/>}/>
                <Route path="/corp-register" element={<CorpRegister/>}/>
                <Route path="/maildomain" element={<MailDomain/>}/>
            </Routes>
        </Router>
    );
}

export default App;